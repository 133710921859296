import React from 'react';
import styled from 'styled-components';
import logoSrc from '../../resources/logo.svg';

const MenuContainer = styled.ul`
  width: 100%;
  display: flex;
  justify-content: center;
  list-style-type: none;
  align-items: center;
  margin: 10px;
  position: relative;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 99;
`;

const MenuLiWraper = styled.li`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  text-transform: uppercase;
`;

const MenuLinkWrapper = styled.a`
  color: #8881ca;
  font-weight: bold;
  text-decoration: none;
  &:hover {
    color: #fff;
  }
`;

const MenuLogoWrapper = styled.img`
  height: 100%;
  width: auto;
  cursor: pointer;
`;

export function Menu() {
  return (
    <MenuContainer className="noselect container ">
      <MenuLiWraper>
        <MenuLogoWrapper src={logoSrc} alt="logo" />
      </MenuLiWraper>
    </MenuContainer>
  );
}
