import styled from 'styled-components';
import Typed from 'react-typed';
import { Fade } from 'react-awesome-reveal';

const TitleWrapper = styled.div`
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: bold;
  font-style: italic;
  text-align: center;
  display: flex;
  flex: 1;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 50%;
  margin-left: 3rem;
  margin-top: 5rem;
  text-align: left;

  @media only screen and (max-width: 768px) {
    font-size: 2rem;
    max-width: 100%;
    margin-left: 0;
    padding: 0 3rem 0 3rem;
  }
`;

const TitleBox = styled.div`
  @media only screen and (max-width: 768px) {
    min-width: 100%;
    text-align: center;
  }
`;

const TypedWrapper = styled(Typed)`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;

  @media only screen and (max-width: 768px) {
    font-size: 1.5rem;
    text-align: center;
  }
`;

const titles = [
  'fullstack engineer',
  'fullstack developer',
  'pragmatic programmer',
  'clean coder',
  'coding ninja',
  'web engineer',
  'web programmer',
  'web developer'
];

export function AuthorTitle() {
  return (
    <TitleWrapper className="container">
      <Fade duration={12000} style={{ width: 'inherit' }}>
        <TitleBox>React & Node</TitleBox>
        <TypedWrapper strings={titles} typeSpeed={160} backSpeed={80} loop />
      </Fade>
    </TitleWrapper>
  );
}
