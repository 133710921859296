import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import Reveal from 'react-awesome-reveal';
import { keyframes } from '@emotion/react';
import styled from 'styled-components';

const fadeInAnimation = keyframes`
   from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const ReactCardFlipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-flow: column;
`;

const FlipCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

interface FlipCardProps {
  faceCard: React.ReactNode;
  backCard: React.ReactNode;
}

const ContactButton = styled.button`
border-radius: 5px;
background: transparent;
border: 3px solid #8881ca;
padding: 10px 20px;
font-size: 18px;
font-weight: bold;
cursor: pointer;
color: #8881ca;
margin-top: 20px;
position: relative;
z-index: 1;
overflow: hidden;
transition: color 1s;
text-transform: uppercase;
width: 145px;

&:before {
  content: '';
  width: 120%;
  height: 120%;
  background: #8881ca;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform-origin: bottom left;
  transform: rotate(-90deg);
  transition: transform 1s;
  border-radius: 3px;
}

&:hover:before {
  transform: rotate(0deg);
}

&:hover {
  color: #fff;
}
`;

export const FlipCard: React.FC<FlipCardProps> = ({ faceCard, backCard }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const flipClickHandler = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <ReactCardFlipWrapper>
      <ReactCardFlip isFlipped={isFlipped}>
        <FlipCardWrapper>{faceCard}</FlipCardWrapper>
        <FlipCardWrapper>{backCard}</FlipCardWrapper>
      </ReactCardFlip>
      <Reveal keyframes={fadeInAnimation} duration={2000} delay={500}>
        <ContactButton type="button" onClick={flipClickHandler}>
          {isFlipped ? 'Flip back' : 'Contact'}
        </ContactButton>
      </Reveal>
    </ReactCardFlipWrapper>
  );
};
