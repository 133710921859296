import React from 'react';
import styled from 'styled-components';
import Reveal from 'react-awesome-reveal';
import { keyframes } from '@emotion/react';
import Tilt from 'react-parallax-tilt';

import shieldSrc from '../../resources/shield.png';
import authorSrc from '../../resources/author.png';

const fadeFromTopAnimation = keyframes`
   from {
    opacity: 0;
    transform: translate3d(0, -75px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeFromBottomAnimation = keyframes`
   from {
    opacity: 0;
    transform: translate3d(0, 75px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeFromFarLeftAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-150px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeFromFarRightAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(150px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeIn = keyframes`
   from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const CardContainer = styled.div`
  flex: 1;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

const ShieldContainer = styled.div`
  padding: 20px;
  height: 440px;
  width: 360px;
  background: url(${shieldSrc}) no-repeat center / 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 9;
`;

const CardContent = styled.div`
  z-index: 99;
  width: 90%;
  height: 80%;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-flow: column;
  position: relative;
`;

const AuthorAvatarContainer = styled.div`
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
`;

const AuthorAvatar = styled.div`
  background: url(${authorSrc}) no-repeat center / cover;
  border-radius: 100%;
  width: 130px;
  height: 130px;
  border: 5px solid #8881ca;
  position: relative;
`;

const AuthorNameWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
`;

const AuthorName = styled.div`
  background: #fff;
  color: #8881ca;
  text-align: center;
  border: 5px solid #8881ca;
  font-weight: bold;
`;

const LightOrbContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LightOrb = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  box-shadow: 0 0 65px 175px rgba(0, 0, 0, 0.5);
  background: #fff;
`;

const LinkBox = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  padding: 1rem;
  position: relative;
  top: -1rem;
  
  &:hover {
    color: #fff;
    background: rgba(255, 2555, 255, 0.1);
  }
`;

const ImageBox = styled.span`
  display: flex;
  justify-content: center;

  & > .containing-img {
    width: 100%;
  }
`;

const SocialImage = styled.i`
  font-size: 3rem;
  
  &.github-img {
      color: #F05033;
  }
    
  &.linkedin-img {
      color: #007AB5;
  }
  
`

export const ContactCard: React.FC = () => {
  return (
    <CardContainer>
      <LightOrbContainer>
        <Reveal keyframes={fadeIn} delay={1000} duration={10000}>
          <LightOrb />
        </Reveal>
      </LightOrbContainer>
      <AuthorAvatarContainer>
        <Reveal keyframes={fadeFromTopAnimation}>
          <Tilt scale={1.5} transitionSpeed={2500}>
            <AuthorAvatar>
              <AuthorNameWrapper>
                <Reveal delay={500} keyframes={fadeFromFarRightAnimation}>
                  <AuthorName>Gabriel Drutu</AuthorName>
                </Reveal>
              </AuthorNameWrapper>
            </AuthorAvatar>
          </Tilt>
        </Reveal>
      </AuthorAvatarContainer>
      <Reveal keyframes={fadeFromBottomAnimation} style={{ zIndex: 9 }}>
        <ShieldContainer>
          <CardContent>
            <LinkBox
              href="https://www.linkedin.com/in/drutugabriel/"
              className="box"
              target="_blank"
              rel="noreferrer"
            >
              <ImageBox>
                <SocialImage
                  className="social-img linkedin-img fa fa-linkedin"
                  aria-hidden="true"
                ></SocialImage>
              </ImageBox>
              <span>LinkedIn</span>
            </LinkBox>
            <LinkBox
              href="https://github.com/DrutuGabriel"
              className="box"
              target="_blank"
              rel="noreferrer"
            >
              <ImageBox>
                <SocialImage
                  className="social-img github-img fa fa-github"
                  aria-hidden="true"
                ></SocialImage>
              </ImageBox>
              <span>GitHub</span>
            </LinkBox>
          </CardContent>
        </ShieldContainer>
      </Reveal>
    </CardContainer>
  );
};
