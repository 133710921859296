import React from 'react';
import { IntroScreen } from './components/IntroScreen/IntroScreen.component';

import './normalize.css';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="app-container">
      <IntroScreen />
    </div>
  );
}

export default App;
