import React from 'react';
import styled from 'styled-components';
import Reveal from 'react-awesome-reveal';
import { keyframes } from '@emotion/react';
import Tilt from 'react-parallax-tilt';

import shieldSrc from '../../resources/shield.png';
import authorSrc from '../../resources/author.png';

const fadeFromTopAnimation = keyframes`
   from {
    opacity: 0;
    transform: translate3d(0, -75px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeFromBottomAnimation = keyframes`
   from {
    opacity: 0;
    transform: translate3d(0, 75px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeFromFarLeftAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-150px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeFromFarRightAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(150px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeIn = keyframes`
   from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const CardContainer = styled.div`
  flex: 1;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

const ShieldContainer = styled.div`
  padding: 20px;
  height: 440px;
  width: 360px;
  background: url(${shieldSrc}) no-repeat center / 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 9;
`;

const CardContent = styled.div`
  z-index: 99;
  width: 90%;
  height: 80%;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  position: relative;
`;

const KeyBulletPoint = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 22px;
  font-style: italic;
  color: #fff;
  margin: 15px auto;
  text-align: center;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.35);
`;

const LightOrbContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LightOrb = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  box-shadow: 0 0 65px 175px rgba(0, 0, 0, 0.5);
  background: #fff;
`;

const AuthorAvatarContainer = styled.div`
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
`;

const AuthorAvatar = styled.div`
  background: url(${authorSrc}) no-repeat center / cover;
  border-radius: 100%;
  width: 130px;
  height: 130px;
  border: 5px solid #8881ca;
  position: relative;
`;

const AuthorNameWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
`;

const AuthorName = styled.div`
  background: #fff;
  color: #8881ca;
  text-align: center;
  border: 5px solid #8881ca;
  font-weight: bold;
`;

export const ShieldCard: React.FC = () => {
  const thisYear = new Date().getFullYear();
  const yearsOfProfessionalExperience = thisYear - 2015;
  const yearsOfRemoteWork = thisYear - 2019;

  return (
    <CardContainer>
      <LightOrbContainer>
        <Reveal keyframes={fadeIn} delay={1000} duration={10000}>
          <LightOrb />
        </Reveal>
      </LightOrbContainer>
      <AuthorAvatarContainer>
        <Reveal keyframes={fadeFromTopAnimation}>
          <Tilt scale={1.5} transitionSpeed={2500}>
            <AuthorAvatar>
              <AuthorNameWrapper>
                <Reveal delay={500} keyframes={fadeFromFarRightAnimation}>
                  <AuthorName>Gabriel Drutu</AuthorName>
                </Reveal>
              </AuthorNameWrapper>
            </AuthorAvatar>
          </Tilt>
        </Reveal>
      </AuthorAvatarContainer>
      <Reveal keyframes={fadeFromBottomAnimation} style={{ zIndex: 9 }}>
        <ShieldContainer>
          <CardContent>
            <Reveal keyframes={fadeFromFarLeftAnimation} delay={500}>
              <KeyBulletPoint>
                {yearsOfProfessionalExperience}+ years of building web
                applications
              </KeyBulletPoint>
            </Reveal>
            <Reveal keyframes={fadeFromFarRightAnimation} delay={1000}>
              <KeyBulletPoint>
                {yearsOfRemoteWork}+ years of successful remote work
              </KeyBulletPoint>
            </Reveal>
            <Reveal keyframes={fadeFromFarLeftAnimation} delay={1500}>
              <KeyBulletPoint>SOLID Principles</KeyBulletPoint>
            </Reveal>
            <Reveal keyframes={fadeFromFarRightAnimation} delay={2000}>
              <KeyBulletPoint>Clean Code</KeyBulletPoint>
            </Reveal>
          </CardContent>
        </ShieldContainer>
      </Reveal>
    </CardContainer>
  );
}
